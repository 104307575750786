<template>
    <v-card flat class="mb-7 w-100">
        <v-card-text class="pa-5">
            <span class="lstick"></span>
            <h3 class="title font-weight-regular">Comentarios Recientes</h3>
        </v-card-text>
        <v-virtual-scroll
                :items="comments"
                :item-height="70"
                height="400">
            <template v-slot:default="{ index, item }">
                <v-list-item :key="index">
                    <v-list-item-avatar>
                        <v-avatar
                                :color="getColor()"
                                size="56"
                                class="white--text">
                            {{ item.name | initials }}
                        </v-avatar>
                    </v-list-item-avatar>
                
                    <v-list-item-content>
                        <v-list-item-title v-html="item.name"></v-list-item-title>
                        <v-list-item-subtitle v-html="item.comment"></v-list-item-subtitle>
                        <!--<v-card-text v-html="item.comment"></v-card-text>-->
                        <v-list-item-subtitle class="mt-2 grey--text">
                            <span><small>Posteado en {{ item.created_at | formatDate }}</small></span>
                        </v-list-item-subtitle>
                        <v-divider v-if="index < comments.length - 1" :key="index"></v-divider>
                    </v-list-item-content>
                
                    <v-list-item-action>
                        <v-btn plain
                               depressed
                               icon>
                            <v-icon small
                                    color="orange darken-4">
                                mdi-open-in-new
                            </v-icon>
                        </v-btn>
                    </v-list-item-action>
                </v-list-item>
            </template>
        </v-virtual-scroll>
    </v-card>
</template>

<script>
    import dashboardService from '@/providers/DashboardService';
    import moment from "moment";
    
    export default {
        name: "TheRecentComments",
        props: {
            comment: Object,
            
        },
        data: () => ({
            comments: [],
           /* comments: [
                {
                    img: "/1.jpg",
                    activeClass: "",
                    username: "James Anderson",
                    desc:
                        "Lorem Ipsum is simply dummy text of the printing and type setting industry.",
                    date: "April 14, 2020",
                    badgebg: "blue lighten-4 blue--text text--darken-1",
                    status: "Pending",
                },
                {
                    img: "/2.jpg",
                    activeClass: "active",
                    username: "Michael Jorden",
                    desc:
                        "Lorem Ipsum has beenorem Ipsum is simply dummy text of the printing and type setting industry.",
                    date: "April 14, 2020",
                    badgebg: "green lighten-4 green--text text--darken-1",
                    status: "Approved",
                },
                {
                    img: "/3.jpg",
                    activeClass: "",
                    username: "Johnathan Doeting",
                    desc:
                        "Lorem Ipsum is simply dummy text of the printing and type setting industry.",
                    date: "April 14, 2020",
                    badgebg: "red lighten-4 red--text text--darken-1",
                    status: "Rejected",
                },
                {
                    img: "/4.jpg",
                    activeClass: "",
                    username: "James Anderson",
                    desc:
                        "Lorem Ipsum is simply dummy text of the printing and type setting industry.",
                    date: "April 14, 2020",
                    badgebg: "blue lighten-4 blue--text text--darken-1",
                    status: "Pending",
                },
                {
                    img: "/5.jpg",
                    activeClass: "",
                    username: "Nirav joshi",
                    desc:
                        "Lorem Ipsum is simply dummy text of the printing and type setting industry.",
                    date: "August 14, 2020",
                    badgebg: "amber lighten-4 amber--text text--darken-1",
                    status: "Pending",
                },
            ],*/
        }),
        filters: {
            formatDate (value) {
                if (value) {
                    return moment(String(value)).locale('es').format('MMM D, YYYY [a las] hh:mm a');
                }
            },
            initials(value) {
                const name = value.split(' ');
                return `${name[0].charAt(0).toUpperCase()}${name[1] ? name[1].charAt(0).toUpperCase() : ''}`;
            },
            trimText(value) {
                const name = value.trim();
                return name;
            }
        },
        methods: {
            genRandomIndex (length) {
                return Math.ceil(Math.random() * (length - 1))
            },
            getColor() {
                let colors = [
                    "info",
                    "success",
                    "primary",
                    "teal",
                    "deep-purple accent2",
                    "red lighten-1",
                    "pink darken-2",
                    "grey darken-2",
                    "brown",
                    "blue-grey darken-3",
                    "red darken-3",
                    "green"
                ];
                const colorsLength = colors.length;
                let colorsel = this.genRandomIndex(colorsLength);
                return colors[colorsel];
            },
            getComments() {
                dashboardService.getRecientComments().then(record => {
                    this.comments = record.value;
                });
            },
        },
        mounted() {
            this.comments = [];
            this.getComments();
        }
    };
</script>